import { ButtonStyles } from '@/../../fitify-ui-landing/src/components/Button/Button.Styles'
import { StyledIcon, TextStyles, colors } from 'fitify-ui'
import { StyledContainer } from 'fitify-ui-landing/src/components/Container/Container.Styled'
import { phone, biggerPhone } from 'fitify-ui-landing/src/theme/breakpoints'
import styled, { css } from 'styled-components'

export const StyledSpecialOfferContainer = styled(StyledContainer)`
  align-items: start;
  display: flex;
  height: 100%;
  ${phone(css`
    padding: 0 24px;
  `)};
`

export const StyledSpecialOfferContentContainer = styled.div`
  display: flex;
  ${phone(css`
    align-items: center;
    align-content: center;
  `)};
`

export const StyledSpecialOfferContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${StyledIcon} {
    color: ${colors.Red400};
    margin-right: 8px;
    zoom: 0.7;
    ${phone(css`
      flex: 0 0 auto;
    `)};
  }
  ${TextStyles}:nth-child(1) {
    font-weight: 600;
  }
  ${TextStyles}:nth-child(2) {
    font-weight: 600;
    margin-right: 24px;
  }
  ${TextStyles}:nth-child(3) {
    ${phone(css`
      font-size: 12px;
    `)};
  }

  ${biggerPhone(css`
    justify-content: center;
  `)};

  ${ButtonStyles} {
    ${phone(css`
      flex: 0 0 auto;
    `)};
  }
`

export const StyledSpecialOffer = styled.div`
  position: fixed;
  z-index: 666;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-self: center;
  backdrop-filter: blur(12px);
  background: rgba(255, 255, 255, 0.75);
  padding: 12px 0 max(env(safe-area-inset-bottom, 0), 12px);
  transition: all 200ms ease;
  &:hover {
    background-color: ${colors.Gray300};
    background: rgba(255, 255, 255, 0.75);
    ${StyledSpecialOfferContent} {
      ${TextStyles}:nth-child(3) {
        color: ${colors.Black};
      }
    }
  }

  ${phone(css`
    padding: 12px 0 max(env(safe-area-inset-bottom, 0), 12px);
  `)};
`
