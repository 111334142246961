import { Icon, Text } from 'fitify-ui'
import Button from 'fitify-ui-landing/src/components/Button/Button'
import { usePromoLink } from 'fitify-ui-landing/src/utils/link'
import { useTranslation } from 'next-i18next'

import {
  StyledSpecialOffer,
  StyledSpecialOfferContainer,
  StyledSpecialOfferContent,
  StyledSpecialOfferContentContainer,
} from './SpecialOffer.Styled'

const SpecialOffer = () => {
  const { t } = useTranslation()

  const promoLink = usePromoLink(process.env.NEXT_PUBLIC_ONBOARDING_URL)

  return (
    <StyledSpecialOffer rel={'noopener noreferrer'}>
      <StyledSpecialOfferContainer>
        <StyledSpecialOfferContent>
          <StyledSpecialOfferContentContainer>
            <Icon name={'clock-alarm'} />
            <Text
              as={'span'}
              style={{ marginLeft: '10px' }}
              color={'Black'}
              variant={'special-offer'}
              html={t('hc_landing_deal2')}
            />
          </StyledSpecialOfferContentContainer>
          <Button href={promoLink} target="_blank" variant={'special-offer'}>
            {t('hc_landing_get_your_trainer')}
          </Button>
        </StyledSpecialOfferContent>
      </StyledSpecialOfferContainer>
    </StyledSpecialOffer>
  )
}

export default SpecialOffer
